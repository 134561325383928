import dayjs from "dayjs";
import React, { useCallback, useMemo, useState, useContext } from "react";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router";

import DeliveryChanges from "./deliveryChanges";

import DatePicker from "@/components/DatePicker";
import InputError from "@/components/InputError";
import { InputField } from "@/components/InputField";
import { InfoBox } from "@/components/InfoBox";
import AppContext from "@/contexts/appContext";
import { FormErrors, FormSubmit, useForm } from "@/utils/form";
import { useTranslate, TranslationFunction } from "@/i18n";
import colors from "@/theme/colors";
import { groupAddressChange } from "@/utils/address";
import routes from "@/routes/routes";
import { log } from "@/logging/logger";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useNavigateToReceipt } from "@/pages/ReceiptPage/components/navigateToReceipt";
import { ReceiptType } from "@/pages/ReceiptPage/receiptTypes";
import { pauseDelivery } from "@/fetch/subscription/pauseDelivery";
import { AddressChange } from "@/fetch/address/getAddressChanges";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Form = {
  fromDate: string;
  toDate: string;
};

const validator = (
  values: Form,
  t: TranslationFunction<typeof formMessages>,
): FormErrors<Form> => {
  const errors: FormErrors<Form> = {};

  if (!values.fromDate) {
    errors.fromDate = t("missingFromDate");
    return errors;
  }
  if (!values.toDate) {
    errors.toDate = t("missingToDate");
    return errors;
  }
  if (dayjs(values.toDate).isBefore(dayjs(values.fromDate))) {
    errors.toDate = t("toFromMismatch");
  }
  return errors;
};

type Props = {
  subscription: Subscription;
  addressChanges?: AddressChange[];
};

const PauseDeliveryForm: React.FC<Props> = ({
  subscription,
  addressChanges,
}) => {
  const t = useTranslate(messages);
  const context = useContext(AppContext);
  const { domain } = context.site;
  const [alreadyPaused, setAlreadyPaused] = useState(false);
  const navigate = useNavigate();
  const navigateToReceipt = useNavigateToReceipt();

  const initial = useMemo(
    () => ({
      fromDate:
        subscription.earliestDeliveryChange || dayjs().format("YYYY-MM-DD"),
      toDate: dayjs(subscription.earliestDeliveryChange)
        .add(7, "day")
        .format("YYYY-MM-DD"),
    }),
    [subscription.earliestDeliveryChange],
  );

  const changes = addressChanges && groupAddressChange(addressChanges, dayjs());

  const onSubmit: FormSubmit<{ toDate: string; fromDate: string }> =
    useCallback(
      async ({ toDate, fromDate }) => {
        const { data, error } = await pauseDelivery({
          formData: { toDate, fromDate },
          subscriptionId: subscription.id,
          publicationDomain: domain,
        });

        if (data?.status === "success") {
          return navigateToReceipt({
            type: ReceiptType.TemporaryStop,
            fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
            toDate: dayjs(toDate).format("YYYY-MM-DD"),
          });
        }

        if (data?.status === "already_paused") {
          setAlreadyPaused(true);
          return;
        }

        log.error(
          "Pause delivery failed",
          data?.error ?? error ?? "Unknown error",
        );
        return navigate(routes.error.path());
      },
      [subscription.id, domain, navigate, navigateToReceipt],
    );

  const { submitForm, values, errors, setValue, isSubmitting } = useForm(
    initial,
    formMessages,
    validator,
    onSubmit,
  );

  const onFromDateChanged = useCallback(
    (selectedData: string) => {
      setValue("fromDate", selectedData);
    },
    [setValue],
  );

  const onToDateChanged = useCallback(
    (selectedData: string) => {
      setValue("toDate", selectedData);
    },
    [setValue],
  );

  return (
    <PauseDeliveryFormWrapper>
      {subscription.isDeliveryPaused || alreadyPaused ? (
        <InfoBox>
          {alreadyPaused ? t("alreadyPaused") : t("subscriptionPaused")}
          <Link to={routes.changes.path(subscription.id)}>
            {t("subscriptionChanges")}.
          </Link>
        </InfoBox>
      ) : (
        <>
          <Info>
            {t("info")
              .split("\n")
              .map((text) => (
                <p key={text}>{text}</p>
              ))}
          </Info>
          {changes &&
            (changes.active.length > 0 || changes.future.length > 0) && (
              <DeliveryChanges
                changes={{
                  active: changes.active || [],
                  future: changes.future || [],
                }}
                subscriptionId={subscription.id}
              />
            )}
          <form onSubmit={submitForm}>
            <InputField error={errors.fromDate} description={t("fromDate")}>
              <DatePicker
                minDate={initial.fromDate}
                selectedDate={values.fromDate}
                onChange={onFromDateChanged}
                $error={!!errors.fromDate}
              />
            </InputField>
            <InputField error={errors.toDate} description={t("toDate")}>
              <DatePicker
                minDate={values.fromDate}
                selectedDate={values.toDate}
                onChange={onToDateChanged}
                $error={!!errors.toDate}
              />
            </InputField>
            <InputError error={errors.global} />
            <SubmitContainer>
              <BrickButton
                as="button"
                type="submit"
                label={t("submit")}
                version="primary"
                disabled={isSubmitting}
                width="normal"
              />
            </SubmitContainer>
          </form>
        </>
      )}
    </PauseDeliveryFormWrapper>
  );
};

const SubmitContainer = styled.div`
  margin-top: 40px;
`;

const Info = styled.div`
  padding: 10px 25px;
  margin-bottom: 20px;
  background: ${colors.contrastBeigeLight};
`;

const PauseDeliveryFormWrapper = styled.div`
  margin-top: 25px;
`;

export default PauseDeliveryForm;

const formMessages = {
  missingFromDate: {
    nb: "Ugyldig fra dato",
    nn: "Ugyldig frå dato",
  },
  missingToDate: {
    nb: "Ugyldig til dato",
    nn: "Ugyldig til dato",
  },
  toFromMismatch: {
    nb: "Til datoen må være etter fra datoen",
    nn: "Til datoen må vere etter frå datoen",
  },
};

const messages = {
  fromDate: {
    nb: "Når reiser du bort?",
    nn: "Når reiser du bort?",
  },
  toDate: {
    nb: "Hvor lenge blir du borte?",
    nn: "Kor lenge blir du borte?",
  },
  submit: {
    nb: "Send inn",
    nn: "Send inn",
  },
  success: {
    nb: "Midlertidig stans utført",
    nn: "Mellombels stopp utført",
  },
  info: {
    nb: `Selv om du tar en pause fra papiravisen, har du fortsatt full digital tilgang.
    Betalingen av abonnementet ditt vil ikke bli påvirket.`,
    nn: `Sjølv om du tar ei pause frå papiravisa, har du fortsatt full digital tilgang.
    Betalinga av abonnementet ditt vil ikkje bli påvirka.`,
  },
  subscriptionPaused: {
    nb: "Levering av papiravis for dette abonnementet er satt på pause. For mer informasjon, se ",
    nn: "Levering av papiravis for dette abonnementet er satt på pause. For meir informasjon, sjå ",
  },
  alreadyPaused: {
    nb: "Kunne ikke registere pause i levering av papiravis for dette abonnementet. Det er allerede registrert en pause i levering i den oppgitte perioden. For mer informasjon, se ",
    nn: "Kunne ikkje registrere pause i levering av papiravis for dette abonnementet. Det er allereie registrert ein pause i levering i den oppgitte perioden. For meir informasjon, sjå ",
  },
  subscriptionChanges: {
    nb: "leveringsendringer",
    nn: "leveringsendringar",
  },
};
