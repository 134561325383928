import React from "react";
import { useOutletContext } from "react-router";

import { StartAutoInvoiceForm } from "./startAutoInvoiceForm";

import { ButtonContainer } from "@/components/ButtonContainer";
import { InnerSheet } from "@/components/Sheet";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useTranslate } from "@/i18n";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = {
  subscription: Subscription;
  key?: string | number;
};

const AutoInvoice: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);
  const { user } = useOutletContext<SubscriptionOutlet>();
  const title = user.extraData.showAutoInvoicePromo
    ? t("incentive")
    : t("title");

  return (
    <InnerSheet data-testid="change-to-auto-invoice">
      <div>{t("recommended")}</div>
      <div>
        <strong>{title}</strong>
      </div>
      <p>{t("description")}</p>

      <ButtonContainer>
        <StartAutoInvoiceForm
          subscriptionId={subscription.id}
          kidNumber={subscription.kidNumber}
          submitButton={() => (
            <BrickButton
              as="button"
              type="submit"
              label={t("cta")}
              version="primary"
              width="normal"
            />
          )}
        />
      </ButtonContainer>
    </InnerSheet>
  );
};

export default AutoInvoice;

const messages = {
  recommended: {
    nb: "Anbefalt!",
    nn: "Anbefalt!",
  },
  title: {
    nb: "AvtaleGiro",
    nn: "AvtaleGiro",
  },
  incentive: {
    nb: "AvtaleGiro - spar 100 kroner på neste faktura",
    nn: "AvtaleGiro - spar 100 kroner på neste faktura",
  },
  description: {
    nb: "Betal i tide med automatisk trekk fra banken. Unngå purringer og gebyrer. Det tar bare et par minutter å bytte. Hvis du allerede har opprettet AvtaleGiro kan du se bort ifra dette.",
    nn: "Betal i tide med automatisk trekk frå banken. Unngå purringar og gebyr. Det tek berre eit par minuttar å byte. Dersom du allereie har opprettet AvtaleGiro kan du se bort ifra dette.",
  },
  cta: {
    nb: "Bytt til AvtaleGiro",
    nn: "Byt til AvtaleGiro",
  },
};
