import { FetchParams, amediaFetch } from "@amedia/fetch";

import {
  HttpError,
  DecodingError,
  ClientError,
  isClientNetworkError,
} from "./errors";

export const fetchWithExtendedError =
  <T, S>(decoder: (a: S) => T, options: FetchParams = { method: "GET" }) =>
  async (url: string): Promise<T> =>
    fetchWithExtendedErrorInternal(decoder, url, options).then((result) => {
      if (result instanceof Error) {
        throw result;
      }
      return result;
    });

type FetchResult<T> = T | Error;

const fetchWithExtendedErrorInternal = async <T, S>(
  decoder: (a: S) => T,
  url: string,
  options: FetchParams = { method: "GET" },
): Promise<FetchResult<T>> => {
  try {
    const res = await amediaFetch(url, { ...options });
    if (!res.ok) {
      const methods: Record<string, string> = {
        get: "fetching",
        post: "posting",
        delete: "deleting",
        default: "fetching",
      };

      const methodText = (key?: string) =>
        key ? methods[key.toLowerCase()] : methods.default;

      const error = new HttpError(
        `An error occurred while ${methodText(options.method)} data.`,
      );
      error.status = res.status;
      return error;
    }

    const data = await res.json();
    return decoder(data);
  } catch (e: unknown) {
    if (e instanceof DecodingError || e instanceof HttpError) {
      return e;
    }

    if (isClientNetworkError(e)) {
      return new ClientError(`Client error: ${e.message}`);
    }

    if (e instanceof Error && "status" in e) {
      // Custom error from fetch, probably from amediaFetch
      return e;
    }

    return new Error(`Got exception while fetching data [${e}]`);
  }
};
