import React from "react";
import { Link, useOutletContext } from "react-router";

import LostProductFeatures from "./lostProductFeatures";
import WinbackSubscriptionOffering from "./winbackSubscriptionOffering";

import { ButtonContainer } from "@/components/ButtonContainer";
import Heading from "@/components/Heading";
import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import { useTranslate } from "@/i18n";
import routes from "@/routes/routes";
import { Family } from "@/services/mercury/families/internal";
import { getVacantSpaces } from "@/utils/groups";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = {
  families: Family[];
  handleNext: () => void;
};

const CancelSubscriptionInfo: React.FC<Props> = (props) => {
  const { subscription, user } = useOutletContext<SubscriptionOutlet>();
  const t = useTranslate(messages);
  const vacantSpacesInFamily = getVacantSpaces(props.families);

  return (
    <>
      <WinbackSubscriptionOffering />
      <PageHeader>{t("title")}</PageHeader>
      <Sheet $primary>
        <Heading>{t("heading", { name: user.name.first })}</Heading>
        <LostProductFeatures
          productFeatures={subscription.product.productFeatures}
          vacantSpacesInFamily={vacantSpacesInFamily}
        />
        <ButtonContainer>
          <BrickButton
            as="button"
            label={t("continue")}
            version="primary"
            onClick={props.handleNext}
          />
          <Link to={routes.myPage.path()}>
            <BrickButton as="span" label={t("cancel")} version="outlined" />
          </Link>
        </ButtonContainer>
      </Sheet>
    </>
  );
};

export default CancelSubscriptionInfo;

const messages = {
  title: {
    nb: "Si opp abonnementet",
    nn: "Sei opp abonnementet",
  },
  heading: {
    nb: "Så synd at du vil si opp, {{name}}. Dette er fordelene du mister:",
    nn: "Så synd at du vil seie opp, {{name}}. Dette er fordelene du mistar:",
  },
  journalismTitle: {
    nb: "Plussinnhold",
    nn: "Plussinnhald",
  },
  journalismText: {
    nb: 'Tilgang til å lese alt på <a href="//{{domain}}">{{domain}}</a>.',
    nn: 'Tilgang til å lese alt på <a href="//{{domain}}">{{domain}}</a>.',
  },
  digitalTitle: {
    nb: "Digital papiravis",
    nn: "Digital papiravis",
  },
  digitalText: {
    nb: "Les papiravisen med eAvis.",
    nn: "Les papiravisen med eAvis.",
  },
  sportTitle: {
    nb: "Direktesendt sport",
    nn: "Direktesendt sport",
  },
  sportText: {
    nb: 'Tilgang til tusenvis av direktesendte kamper og konkurranser i en rekke sporter på <a href="//{{domain}}">{{domain}}</a>.',
    nn: 'Tilgang til tusenvis av direktesendte kamper og konkurranser i ein rekkje sporter på <a href="//{{domain}}">{{domain}}</a>.',
  },
  familysharingTitle: {
    nb: "Husstandsdeling",
    nn: "Husstandsdeling",
  },
  familysharingText: {
    nb: "Kostnadsfri deling av abonnement med andre i din husstand. ",
    nn: "Kostnadsfri deling av abonnement med andre i din husstand. ",
  },
  allotmentsInfo: {
    nb: 'Du har <a href="{{familyUrl}}">{{count}} ledig plass</a>.',
    nn: 'Du har <a href="{{familyUrl}}">{{count}} ledig plass</a>.',
  },
  allotmentsInfoPlural: {
    nb: 'Du har <a href="//{{familyUrl}}">{{count}} ledige plasser</a>.',
    nn: 'Du har <a href="//{{familyUrl}}">{{count}} ledige plassar</a>.',
  },
  continue: {
    nb: "Fortsett oppsigelsen",
    nn: "Hald fram med å seie opp avisa",
  },
  cancel: {
    nb: "Behold abonnementet",
    nn: "Behald abonnementet",
  },
};
