// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// This file is based on these docs https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/genesysgf
function initGenesys(g, e, n, es, ys) {
  g["_genesysJs"] = e;
  g[e] =
    g[e] ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (g[e].q = g[e].q || []).push(arguments);
    };
  g[e].t = 1 * new Date();
  g[e].c = es;
  ys = document.createElement("script");
  ys.async = 1;
  ys.src = n;
  ys.charset = "utf-8";
  document.head.appendChild(ys);
}

type InitChat = {
  queueName?: string;
  userName?: string;
};

export default function initGenesysChat({ queueName, userName }: InitChat) {
  initGenesys(
    window,
    "Genesys",
    "https://apps.mypurecloud.ie/genesys-bootstrap/genesys.min.js",
    {
      environment: "prod-euw1",
      deploymentId: "7fd140c7-22e1-4b7d-901a-84b6498eec22",
      debug: false,
    },
  );

  // The Genesys() global function has a list of what they call "plugins" that can be subscribed to.
  // Or to be executed as commands.
  // The list of plugins can be found here:
  // https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/SDKCommandsEvents/

  Genesys("subscribe", "Database.ready", function () {
    // Set user and queuename after database is ready
    Genesys("command", "Database.set", {
      messaging: {
        customAttributes: {
          queueName: queueName || "ABC Amedia Kundesenter",
          name: userName || "Gjest",
          website: window.location.origin,
        },
      },
    });
  });

  // Genesys("subscribe", "Database.updated", function (e) {
  //   console.log("Genesys database updated");
  //   console.log(e.data);
  // });
}
